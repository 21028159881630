<template>
  <div>
    <v-tabs v-model="strTab" centered icons-and-text>
      <v-tab href="#tab-geral">
        Informações do Agendamento
        <i :class="$utilidade.getIcone('calendario') + ' fa-2x'"></i>
      </v-tab>
      <v-tab href="#tab-exames" v-if="formData.exames.length">
        Exames Complementares
        <i :class="$utilidade.getIcone('exame') + ' fa-2x'"></i>
      </v-tab>
      <v-tab href="#tab-interacao" v-if="formData.interacoes.length">
        Interações
        <i :class="$utilidade.getIcone('interacao') + ' fa-2x'"></i>
      </v-tab>
      <v-tab href="#tab-notificacao-historico">
        Notificação
        <i :class="$utilidade.getIcone('notificacao') + ' fa-2x'"></i>
      </v-tab>
    </v-tabs>

    <exame
      class="row ma-0 mt-3 mb-3"
      v-if="strTab == 'tab-exames'"
      :formData="formData"
      disabledRegisterRows
    />

    <interacao
      class="row ma-0 mt-3 mb-3"
      v-if="strTab == 'tab-interacao'"
      :formData="formData"
    />

    <notificacao
      class="row ma-0 mt-3 mb-3"
      v-if="strTab == 'tab-notificacao-historico'"
      :pointType="'agendamento'"
      :pointId="formData.intId"
      :key="'tab-notificacao-historico-' + $root.$session.versao"
    />

    <div class="row mt-3" v-if="strTab == 'tab-geral'">
      <div class="col-xs-12 col-sm-12 col-md-9 col-lg-9 col-xl-9 pa-0 ma-0">
        <div class="row pa-0 ma-0">
          <ed-input-text
            class="col-xs-12 col-sm-12 col-md-32 col-lg-2 col-xl-2"
            label="Número do Agendamento"
            readonly
            :value="formData.intId"
          />

          <ed-input-text
            class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3"
            label="Data do Agendamento"
            readonly
            :value="
              $utilidade.toDate(
                formData.strDataAgendamento,
                true,
                'DD/MM/YYYY'
              ) +
              ' ' +
              formData.strHoraAgendamentoInicial +
              ' - ' +
              formData.strHoraAgendamentoFinal
            "
          />

          <ed-input-text
            v-if="formData.funcionario"
            class="col-xs-12 col-sm-12 col-md-32 col-lg-2 col-xl-2"
            label="CPF"
            readonly
            :value="formData.funcionario.strCpf"
          />

          <ed-input-text
            v-if="formData.funcionario"
            class="col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5"
            label="Funcionário"
            readonly
            :value="formData.funcionario.strNome"
          />

          <ed-input-text
            v-if="formData.funcao"
            class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3"
            label="Função"
            readonly
            :value="formData.funcao.strNome"
          />

          <ed-input-text
            v-if="formData.unidade"
            class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3"
            label="Unidade"
            readonly
            :value="formData.unidade.strNome"
          />

          <ed-input-text
            v-if="formData.setor"
            class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3"
            label="Setor"
            readonly
            :value="formData.setor.strNome"
          />

          <ed-input-text
            v-if="formData.empresa"
            class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3"
            label="Empresa"
            readonly
            :value="formData.empresa.strNome"
          />
          <ed-alert  type="warning" class="col-12 ma-3 ma-3 mr-0"
            >Esse agendamento está com status
            <b>{{ formData.status.strNome }}</b></ed-alert
          >

          <div class="col-12 text-center">
            <router-link
              :to="{
                name: 'consulta.edit',
                params: { intId: formData.intConsultaId }
              }"
              target="_blank"
              
              v-if="formData.intConsultaId && $route.name.indexOf('consulta') == -1"
            >
             <h3>
               <i :class="$utilidade.getIcone('consulta')" />
              Consulta nº {{formData.intConsultaId }}. Clique aqui para abrir
             </h3>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { EdInputText, EdAlert } from "@/components/common/form";

import CamposPersonalizado from "@/components/cadastro/formularioPersonalizado/formatar";
import Notificacao from "@/components/common/notificacao/index";
import Exame from "./exame.vue";
import Interacao from "./interacao.vue";

export default {
  name: "pageCadastroFuncao",
  props: {
    formData: {
      type: [Object],
      required: true,
      default: function () {
        return null;
      },
    },
  },
  components: {
    EdInputText,
    EdAlert,
    CamposPersonalizado,
    Notificacao,
    Exame,
    Interacao,
  },
  inject: ["abrirConsulta"],
  provide() {
    return {};
  },
  mounted() {},
  created() {},
  data() {
    return {
      strTab: "tab-geral",
    };
  },
  watch: {
    formData: {
      handler: function (newVal) {},
      deep: true,
    },
  },
  computed: {},
  methods: {
    initialize() {},
  },
};
</script>
