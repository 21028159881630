<template>
  <div>
    <div class="d-flex justify-content-center">
      <ed-alert
        type="warning"
        class="text-left col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 ma-0 pa-2"
        >Nesta tela você pode acompanhar todo progresso do agendamento.</ed-alert
      >
    </div>

    <ed-simple-table v-if="formData" disabledSearchRows disabledRegisterRows>
      <thead v-if="formData.interacoes && formData.interacoes.length">
        <tr>
          <th>Data</th>
          <th>Checkin</th>
          <th>Status</th>
          <th>Usuário</th>
          <th>Observações</th>
        </tr>
      </thead>
      <tbody>
        <template v-for="(objAgendamentoCheckin, index) in formData.interacoes">
          <tr :key="'index' + index">
            <td align="left">
              {{ $utilidade.toDate(objAgendamentoCheckin.strDataCadastro, false) }}
            </td>
            <td align="left">
              {{
                objAgendamentoCheckin.strDataEvento
                  ? $utilidade.toDate(
                      objAgendamentoCheckin.strDataEvento,
                      false,
                      "DD/MM/YYYY HH:MM"
                    )
                  : "---"
              }}
            </td>
            <td align="left">
              {{
                objAgendamentoCheckin.status
                  ? objAgendamentoCheckin.status.strNome
                  : "---"
              }}
            </td>
            <td align="left">
              {{
                objAgendamentoCheckin.usuario
                  ? objAgendamentoCheckin.usuario.strNome
                  : "---"
              }}
            </td>
            <td align="left">
              <ed-collapse
                :textValue="objAgendamentoCheckin.strObservacao"
                :textLimit="30"
              />
            </td>
          </tr>
        </template>
      </tbody>
    </ed-simple-table>
  </div>
</template>

<script>
import {
  EdSimpleTable,
  EdButton,
  EdInputMoney,
  EdInputAutoComplete,
  EdInputSelect,
  EdModal,
  EdAlert,
  EdCollapse,
} from "@/components/common/form";
export default {
  name: "pageCadastroFuncao",
  props: {
    formData: {
      type: [Object],
      required: true,
      default: function () {
        return null;
      },
    },
  },
  components: {
    EdSimpleTable,
    EdButton,
    EdInputMoney,
    EdInputAutoComplete,
    EdInputSelect,
    EdModal,
    EdAlert,
    EdCollapse,
  },
  inject: [],
  provide() {
    return {};
  },
  mounted() {
    this.initialize();
  },
  created() {},
  data() {
    return {
      boolExibirModal: false,
      formDataExame: {},
    };
  },
  watch: {
    "formData.intId"() {
      this.initialize();
    },
  },
  computed: {},
  methods: {
    initialize() {
      if (!this.formData.exames) {
        this.formData.exames = [];
      }
    },

    setExame(item) {
      this.formDataExame.exame = item;
    },

    inserirExame() {
      this.$root.$dialog.loading(true);

      this.$root.$request
        .get("Cadastro/TabelaPrecoExame", {
          boolBuscarTabelaPreco: true,
          intExameId: this.formDataExame.intExameId,
          intCadastroGeralUnidadeId: this.formData.intCadastroGeralUnidadeId,
          intCadastroGeralEmpresaId: this.formData.intCadastroGeralEmpresaId,
        })
        .then((objResponse) => {
          this.$root.$dialog.loading(false);

          //TABELA DE PRECO
          let objTabelaPreco = null;
          if (objResponse.status == 200 && objResponse.result) {
            objTabelaPreco = objResponse.result;
          }

          //INSERINDO EXAME
          let objExame = this.$utilidade.arrFindByField(
            this.formData.exames,
            "intExameId",
            this.formDataExame.intExameId
          );

          if (objExame) {
            this.$root.$dialog.warning("Esse exame já foi inserido");
          } else {
            this.formData.exames.push({
              intId: 0,
              intAgendamentoId: this.formData.intId,
              intExameId: this.formDataExame.intExameId,
              exame: this.formDataExame.exame,
              parceiro: objTabelaPreco ? objTabelaPreco.parceiro : null,
              intCadastroGeralParceiroId: objTabelaPreco
                ? objTabelaPreco.parceiro.intId
                : null,
              floatValorVenda: objTabelaPreco ? objTabelaPreco.floatValorVenda : null,
              floatValorCusto: objTabelaPreco ? objTabelaPreco.floatValorCusto : null,
              intTipoStatusId: 313,
              boolNovoRegistro: 1,
              strDataExame: this.$utilidade.toDate(null, true, "YYYY-MM-DD"),
            });

            this.boolExibirModal = !this.boolExibirModal;
          }
        });
    },

    onEdit(data) {},

    onRegister() {
      this.boolExibirModal = !this.boolExibirModal;
    },

    onDelete(item, index) {
      this.$root.$dialog
        .confirm("Tem certeza que deseja excluir o exame `" + item.exame.strNome + "`?")
        .then((boolExcluir) => {
          this.formData.exames.splice(index, 1);
        });
    },
  },
};
</script>
