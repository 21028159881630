import { render, staticRenderFns } from "./interacao.vue?vue&type=template&id=2122db59&"
import script from "./interacao.vue?vue&type=script&lang=js&"
export * from "./interacao.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports