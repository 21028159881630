<template>
  <div>
    <ed-table
    :key="$root.$session.versao"
      strModel="Common/Notificacao"
      :headers="arrayHeaders"
      :rows="arrayRows"
      :pagination="objPagination"
      :loading="loading"
      @search="getNotificacao"
      disabledRegisterRows
      disabledEditRows
      disabledDeleteRows
      disabledSelectedRows
    />
  </div>
</template>

<script>
import { EdTable } from "@/components/common/form";

export default {
  name: "parametro",
  props: {
    pointType: {
      type: [String],
      required: false,
      default: null,
    },
    pointId: {
      type: [String, Number],
      required: false,
      default: null,
    },
    intCodigoInternoId: {
      type: [String, Number],
      required: false,
      default: null,
    },
    strIdentificador: {
      type: [String],
      required: false,
      default: null,
    },
  },
  components: {
    EdTable,
  },
  beforeDestroy() {
    this.arrayHeaders = [];
    this.arrayRows = [];
    this.objPagination = null;
  },
  mounted() {
    this.getNotificacao();
  },
  created() {},
  data() {
    return {
      loading: false,
      arrayHeaders: [
        {
          text: "Título",
          sortable: true,
          value: "strTitulo",
        },
        {
          text: "Destinatário",
          sortable: true,
          value: "strDestinatario",
        },
        {
          text: "Conteúdo",
          sortable: true,
          value: "strConteudo",
          collapse: 1,
          collapseLabel: "Visualizar...",
        },
        {
          text: "Status",
          sortable: true,
          value: "strTipoStatus",
        },
        {
          text: "Data",
          sortable: true,
          value: "strDataCadastro",
        },
      ],
      arrayRows: [],
      objPagination: null,
    };
  },
  watch: {
    pointType() {
      this.getNotificacao();
    },
    pointId() {
      this.getNotificacao();
    },
    strIdentificador() {
      this.getNotificacao();
    },
  },
  computed: {},
  methods: {
    getNotificacao(strBusca) {
      if (!this.pointId || !this.pointType)  {
        return;
      }
      if (!strBusca) {
        strBusca = {};
      }

      this.loading = true;

      this.$root.$request
        .get(
          "Common/Notificacao",
          Object.assign(
            {
              pointId: this.pointId,
              pointType: this.pointType,
              intCodigoInternoId: this.intCodigoInternoId,
              strIdentificador: this.strIdentificador,
            },
            strBusca
          )
        )
        .then((objResponse) => {
          this.loading = false;

          if (objResponse.status == 200 && objResponse.result) {
            let arrayRows = [];

            objResponse.result.data.forEach((objNotificacao) => {
              let item = {
                intId: objNotificacao.intId,
                strTitulo: objNotificacao.strTitulo,
                strConteudo: objNotificacao.strConteudo,
                strDestinatario: objNotificacao.strDestinatario,
                strTipoStatus: objNotificacao.tipo_status
                  ? '<span title="'+(objNotificacao.strErro ? objNotificacao.strErro : '')+'">'+objNotificacao.tipo_status.strNome+'</span>'
                  : "---",
                strDataCadastro: this.$utilidade.toDate(
                  objNotificacao.strDataAtualizacao
                ),
                _item: objNotificacao,
              };

              arrayRows.push(item);
            });

            this.arrayRows = arrayRows;
            //this.$emit('arrayNotificacao',this.arrayRows)
          }
        });
    },
  },
};
</script>
