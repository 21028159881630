<template>
  <div>
    <!--INSERIR NOVO EXAME-->
    <ed-modal v-model="boolExibirModal" width="50%">
      <div slot="title">
        Agendamento {{ formData.intId }} :: Inserir Novo Exame
      </div>

      <div class="" slot="content">
        <div class="row">
          <ed-input-auto-complete
            class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
            v-model="formDataExame.intExameId"
            name="intExameId"
            item-text="strNome"
            label="Exame"
            route="Cadastro/Exame"
            rules="required"
            @selected="setExame"
          />

          <div class="col-12 text-right" slot="footer">
            <ed-button
              :iconLeft="$utilidade.getIcone('salvar')"
              color="success"
              title="Salvar"
              label="Inserir"
              @click="inserirExame"
              :disabled="!formDataExame.intExameId"
            />
          </div>
        </div>
      </div>
    </ed-modal>

    <div class="d-flex justify-content-center">
      <ed-alert
        type="warning"
        class="
          text-left
          col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6
          ma-0
          pa-2
        "
        >Nesta tela você pode visualizar ou inserir exames complementares para o
        agendamento. Os exames serão inseridos automaticamente ao gerar a
        consulta.</ed-alert
      >
    </div>

    <ed-simple-table
      v-if="formData"
      :config="{ registerName: 'Inserir novo exame' }"
      @register="onRegister"
      disabledSearchRows
      :disabledRegisterRows="disabledRegisterRows"
    >
      <thead v-if="formData.exames && formData.exames.length">
        <tr>
          <th width="50">#</th>
          <th>Exame</th>
          <th>Valor</th>
          <th width="50"></th>
        </tr>
      </thead>
      <tbody>
        <template v-for="(objAgendamentoExame, index) in formData.exames">
          <tr :key="index" v-if="!objAgendamentoExame.aproveitamento">
            <td align="left">{{ index + 1 }}</td>
            <td
              align="left"
              :title="
                objAgendamentoExame.boolNovoRegistro
                  ? 'Novo exame inserido'
                  : objAgendamentoExame.status
                  ? 'Exame ' + objAgendamentoExame.status.strNome
                  : ''
              "
              :style="objAgendamentoExame.boolNovoRegistro ? 'color:green' : ''"
            >
              <i
                v-if="objAgendamentoExame.boolNovoRegistro"
                :class="$utilidade.getIcone('novoItem')"
                title="Novo exame inserido"
              />
              <ed-collapse
                :textValue="objAgendamentoExame.exame.strNome"
                :textLimit="30"
              />
            </td>
            <td align="left">
              R$
              {{ $utilidade.floatToMoney(objAgendamentoExame.floatValorVenda) }}
            </td>
            <td align="left">
              <ed-button
                :iconLeft="$utilidade.getIcone('deletar')"
                color="error"
                title="Deletar"
                icon
                onlyIcon
                @click="onDelete(objAgendamentoExame, index)"
              />
            </td>
          </tr>
        </template>
      </tbody>
      <tfoot v-if="formData.exames && formData.exames.length && false">
        <tr>
          <th>
            <h7>TOTAL</h7>
          </th>
          <th></th>
          <th></th>
          <th align="right">
            <h6>R$ {{ getTotalExames() }}</h6>
          </th>
          <th></th>
          <th></th>
        </tr>
      </tfoot>
    </ed-simple-table>
  </div>
</template>

<script>
import {
  EdSimpleTable,
  EdButton,
  EdInputMoney,
  EdInputAutoComplete,
  EdInputSelect,
  EdModal,
  EdAlert,
  EdCollapse,
} from "@/components/common/form";
export default {
  name: "pageCadastroFuncao",
  props: {
    formData: {
      type: [Object],
      required: true,
      default: function () {
        return null;
      },
    },
    disabledRegisterRows:{
      type: [Boolean],
      required: false,
      default: function () {
        return false;
      },
    }
  },
  components: {
    EdSimpleTable,
    EdButton,
    EdInputMoney,
    EdInputAutoComplete,
    EdInputSelect,
    EdModal,
    EdAlert,
    EdCollapse,
  },
  inject: [],
  provide() {
    return {};
  },
  mounted() {
    this.initialize();
  },
  created() {},
  data() {
    return {
      boolExibirModal: false,
      formDataExame: {},
    };
  },
  watch: {
    "formData.intId"() {
      this.initialize();
    },
  },
  computed: {},
  methods: {
    initialize() {
      if (!this.formData.exames) {
        this.formData.exames = [];
      }
    },

    setExame(item) {
      this.formDataExame.exame = item;
    },

    inserirExame() {
      this.$root.$dialog.loading(true);

      this.$root.$request
        .get("Cadastro/TabelaPrecoExame", {
          boolBuscarTabelaPreco: true,
          intExameId: this.formDataExame.intExameId,
          intCadastroGeralUnidadeId: this.formData.intCadastroGeralUnidadeId,
          intCadastroGeralEmpresaId: this.formData.intCadastroGeralEmpresaId,
        })
        .then((objResponse) => {
          this.$root.$dialog.loading(false);

          //TABELA DE PRECO
          let objTabelaPreco = null;
          if (objResponse.status == 200 && objResponse.result) {
            objTabelaPreco = objResponse.result;
          }

          //INSERINDO EXAME
          let objExame = this.$utilidade.arrFindByField(
            this.formData.exames,
            "intExameId",
            this.formDataExame.intExameId
          );

          if (objExame) {
            this.$root.$dialog.warning("Esse exame já foi inserido");
          } else {
            this.formData.exames.push({
              intId: 0,
              intAgendamentoId: this.formData.intId,
              intExameId: this.formDataExame.intExameId,
              exame: this.formDataExame.exame,
              parceiro: objTabelaPreco ? objTabelaPreco.parceiro : null,
              intCadastroGeralParceiroId: objTabelaPreco
                ? objTabelaPreco.parceiro.intId
                : null,
              floatValorVenda: objTabelaPreco
                ? objTabelaPreco.floatValorVenda
                : null,
              floatValorCusto: objTabelaPreco
                ? objTabelaPreco.floatValorCusto
                : null,
              intTipoStatusId: 313,
              boolNovoRegistro: 1,
              strDataExame: this.$utilidade.toDate(null, true, "YYYY-MM-DD"),
            });

            this.boolExibirModal = !this.boolExibirModal;
          }
        });
    },

    onEdit(data) {},

    onRegister() {
      this.boolExibirModal = !this.boolExibirModal;
    },

    onDelete(item, index) {
      this.$root.$dialog
        .confirm(
          "Tem certeza que deseja excluir o exame `" + item.exame.strNome + "`?"
        )
        .then((boolExcluir) => {
          this.formData.exames.splice(index, 1);
        });
    },
  },
};
</script>
